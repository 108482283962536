/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Congregation } from 'src/app/models/congregation';
import { SelectItem, MessageService } from 'primeng/api';
import { States } from '../../../modules/shared/us-states';

import { CongregationWeekend } from '../../../models/congregationWeekend';
import { ConventionJob } from 'src/app/models/conventionJob';
import { ConventionJobRole } from 'src/app/models/conventionJobRole';
import { ConventionJobSubRole } from 'src/app/models/conventionJobSubRole';
import { Facility } from '../../../models/facility';
import { Volunteer } from 'src/app/models/volunteer';
import { Weekend } from '../../../models/weekend';
import { WeekendRoleAssignment } from 'src/app/models/weekendRoleAssignment';

import { FacilitiesService } from '../../../modules/facilities/services/facilities.service';
import { WeekendsService } from '../../../modules/facilities/services/weekends.service';
import { CongregationService } from 'src/app/modules/congregations/services/congregation.service';
import { LookupsService } from 'src/app/core/services/lookups.service';
import { VolunteerRatingModalComponent } from '../../volunteers/volunteer-rating-modal/volunteer-rating-modal.component';

import { ButtonType } from '../../components/button/buttonType';
import { SecurityRole } from '../../security/securityRole';
import { UserAccountService } from 'src/app/core/services/user-account.service';
import { VolunteersService } from 'src/app/modules/congregations/services/volunteers.service';

@Component({
  selector: 'app-facility-weekend',
  templateUrl: './facility-weekend.component.html',
  styleUrls: ['./facility-weekend.component.scss']
  })
export class FacilityWeekendComponent implements OnInit {

  buttonTypes = ButtonType;
  securityRole = SecurityRole;

  technicalContact!: Volunteer;
  facility!: Facility;
  weekend!: Weekend;
  assignments!: WeekendRoleAssignment[];
  assignmentsPage = 1;
  congregations!: Congregation[];
  isLoaded: boolean;
  isLoadingCongregationList: boolean;
  isBusy: boolean;
  isSuperUser = false;
  isInitialGradeImport = false;

  weekendId: number;
  facilityId: number;
  importUrl: string;

  showAddCongregationDialog: boolean;
  selectedCongregation!: Congregation;
  selectCongregationList: Congregation[] = [];
  stateCodes!: SelectItem[];

  showAddRoleAssignmentDialog: boolean;
  conventionJobs: SelectItem[] = [];
  conventionJobRoles: SelectItem[] = [];
  conventionJobSubRoles: ConventionJobSubRole[] = [];
  filteredJobSubRoles: SelectItem[] = [];
  selectedJob: ConventionJob;
  selectedJobRole: ConventionJobRole;
  selectedJobSubRole: ConventionJobSubRole;
  selectedVolunteer: Volunteer;
  volunteerSearchList: Volunteer[];

  @ViewChild('volunteerRatingModal')
  volunteerRatingModal: VolunteerRatingModalComponent;

  constructor(
    private volunteerService: VolunteersService,
    private facilitiesService: FacilitiesService,
    private weekendsService: WeekendsService,
    private congregationService: CongregationService,
    private lookupsService: LookupsService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private userAccountService: UserAccountService,
  ) { }

  ngOnInit(): void {
    this.facilityId = Number(this.route.snapshot.paramMap.get('facilityId'));
    this.weekendId = Number(this.route.snapshot.paramMap.get('weekendId'));

    this.importUrl = `./api/beta/weekends/${this.weekendId}/facility/${this.facilityId}/import/${this.isInitialGradeImport}`;

    this.isSuperUser = this.userAccountService.isAccountInGroup(SecurityRole.SuperUser);
    this.stateCodes = States.toListValue();
    this.isLoadingCongregationList = true;
    this.loadData();
  }

  async loadData(): Promise<void> {
    await this.facilitiesService.getFacility(this.facilityId)
      .then(async result => {
        this.facility = result;
        await this.loadTechnicalContact();
        await this.loadWeekend();
      });

    await this.lookupsService.getConventionJobs()
      .then(result => {
        result.forEach((job) => {
          this.conventionJobs.push({ label: job.jobTitle, value: job });
        });
      });
    await this.lookupsService.getConventionJobRoles()
      .then(result => {
        result.forEach((role) => {
          this.conventionJobRoles.push({ label: role.roleTitle, value: role });
        });
      });
    await this.lookupsService.getConventionJobSubRoles()
      .then(result => {
        this.conventionJobSubRoles = result;
      });
  }

  async loadTechnicalContact(): Promise<void> {
    if (this.facility.technicalContactVolunteerId) {
      await this.volunteerService.getVolunteer(this.facility.technicalContactVolunteerId)
        .then(result => this.technicalContact = result);
    }
  }

  async loadWeekend(): Promise<void> {
    await this.weekendsService.getWeekend(this.weekendId)
      .then(async result => {
        this.weekend = result;
        if (this.weekend.facilityId !== this.facilityId) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Weekend does not belong to this facility' });
          this.router.navigate(['/facilities']);
        }

        await this.loadAssignments();
        await this.loadCongregations();
      });
  }

  async loadAssignments(): Promise<void> {
    this.weekendsService.getAssignmentsByWeekendId(this.weekendId, this.assignmentsPage)
      .then(result => {
        this.assignments = result;
      });
  }

  async loadCongregations(): Promise<void> {
    this.isLoadingCongregationList = true;
    this.weekendsService.getCongregationsForWeekend(this.weekend.weekendId)
      .then(async result => {
        this.congregations = result;
        this.isLoaded = true;
        this.isLoadingCongregationList = false;
      });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  congregationSearch(event: any): void {
    this.congregationService.search(event.query)
      .then(data => {
        this.selectCongregationList = [];
        data.forEach((el) => {
          if (!this.congregations.some(c => c.congregationNumber === el.congregationNumber)) {
            this.selectCongregationList.push(el);
          }
        });
      });
  }

  toggleAdminFunctions(): void {
    document.getElementById('adminFunctionsBody')?.classList.toggle('hidden');
    const icon = document.getElementById('adminFunctionIcon');
    icon?.classList.toggle('rotate-0');
    icon?.classList.toggle('rotate-180');
    document.getElementById('adminFunctionButton')?.classList.toggle('rounded-b-xl');
  }

  onUpload(): void{
    this.loadWeekend();
  }

  onCheckboxChange(): void {
    this.importUrl = `./api/beta/weekends/${this.weekendId}/facility/${this.facilityId}/import/${this.isInitialGradeImport}`;
    console.debug(`Is Initial Grade: [${this.isInitialGradeImport}]`);
    console.debug(`Import Url: [${this.importUrl}]`);
  }

  showAddCongregationToWeekend(): void {
    this.selectedCongregation = new Congregation();
    this.showAddCongregationDialog = true;
  }

  removeCongregation(congregation: Congregation): void {
    const congregationWeekend = new CongregationWeekend();
    congregationWeekend.congregationNumber = congregation.congregationNumber;
    congregationWeekend.weekendId = this.weekend.weekendId;
    this.isLoadingCongregationList = true;
    this.weekendsService.removeCongregationFromWeekend(congregationWeekend)
      .then(() => {
        this.loadCongregations();
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Congregation removed from weekend' });
      });
  }

  cancel(): void {
    this.showAddCongregationDialog = false;
  }

  save(): void {
    if (this.selectedCongregation) {
      const congregationWeekend = new CongregationWeekend();
      congregationWeekend.congregationNumber = this.selectedCongregation.congregationNumber;
      congregationWeekend.weekendId = this.weekend.weekendId;

      this.isLoadingCongregationList = true;
      this.weekendsService.addCongregationToWeekend(congregationWeekend)
        .then(() => {
          this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Congregation added to weekend' });
          this.loadCongregations();
          this.showAddCongregationDialog = false;
        });
    }
  }

  showAddRoleAssignment(): void {
    console.debug('Show Add Role Assignment');
    this.showAddRoleAssignmentDialog = true;
  }

  saveRoleAssignment(): void {
    console.log('Saving role assignment');
    const newAssignment = new WeekendRoleAssignment();
    newAssignment.conventionJobId = this.selectedJob.conventionJobId;
    newAssignment.conventionJobRoleId = this.selectedJobRole.conventionJobRoleId;
    newAssignment.conventionJobSubRoleId = this.selectedJobSubRole.conventionJobSubRoleId;
    newAssignment.volunteerId = this.selectedVolunteer.volunteerId;
    newAssignment.weekendId = this.weekend.weekendId;
    this.weekendsService.addRoleAssignment(newAssignment)
      .then(() => {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Role assignment added to weekend' });
        this.loadWeekend();
        this.showAddRoleAssignmentDialog = false;
      });
  }

  removeRoleAssignment(weekendRoleAssignment: WeekendRoleAssignment): void {
    this.weekendsService.removeRoleAssignment(this.weekend.weekendId, weekendRoleAssignment.weekendRoleAssignmentId)
      .then(() => {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Role assignment removed from weekend' });
        this.loadWeekend();
      });
  }

  populateSubRoles(): void {
    this.filteredJobSubRoles = [];
    this.conventionJobSubRoles.map(role => {
      if (role.conventionJobId === this.selectedJob.conventionJobId) {
        this.filteredJobSubRoles.push({ label: role.subRoleTitle, value: role });
      }
    });
  }

  cancelRoleAssignment(): void {
    this.showAddRoleAssignmentDialog = false;
  }

  openAddGradeModal(weekendRoleAssignment: WeekendRoleAssignment): void {
    this.volunteerRatingModal.show(this.weekend.weekendId, weekendRoleAssignment);
  }

  handleGradeSaved(): void {
    this.loadWeekend();
  }
}
