import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { paths } from './facilities-paths';
import { roles } from '../../features/security/roles';
import { MsalGuardGroupService } from 'src/app/core/guards/msal-guard-group.service';
import { PageFacilitiesComponent } from './pages/page-facilities/page-facilities.component';
import { PageFacilityweekendComponent } from './pages/page-facilityweekend/page-facilityweekend.component';
import { PageAddAssignmentComponent } from './pages/page-add-assignment/page-add-assignment.component';

const routes: Routes = [
  {
    path: paths.facilities,
    component: PageFacilitiesComponent,
    canActivate: [MsalGuardGroupService],
    data: {
      title: 'Facilities',
      icon: 'stadium-variant', sortOrder: 200,
      expectedRoles: [ roles.technicalContact ],
      includeInMenu: true
    },
  },
  {
    path: paths.facilityDetailWeekend,
    component: PageFacilityweekendComponent,
    canActivate: [MsalGuardGroupService],
    data: {
      title: 'Facility Weekend Detail',
      expectedRoles: [ roles.technicalContact ],
      includeInMenu: false
    },
  },
  {
    path: paths.facilityDetailWeekendAddAssignment,
    component: PageAddAssignmentComponent,
    canActivate: [MsalGuardGroupService],
    data: {
      title: 'Facility Weekend - Add Assignment',
      expectedRoles: [ roles.technicalContact ],
      includeInMenu: false
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FacilitiesRoutingModule { }
