import { EventEmitter, Component, OnInit, Input, Output } from '@angular/core';
import { ButtonType } from './buttonType';
import { SecurityRole } from '../../security/securityRole';
import { UserAccountService } from '../../../core/services/user-account.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html'
  })
export class ButtonComponent implements OnInit {
  @Input() label: string;
  @Input() type: ButtonType;
  @Input() requiredRoles: SecurityRole[] = SecurityRole[0];
  @Input() requiredAssignment: string;
  @Input() public hideLabel = false;
  @Input() public initRowEdit = false;
  @Input() styleClass: string;
  @Input() disabled: boolean;
  @Output() click: EventEmitter<unknown> = new EventEmitter();

  public buttonTypes = ButtonType;
  public securityRole = SecurityRole;

  public buttonClass: string;
  public buttonIconClass: string;
  public showIcon: boolean;
  public buttonVisible = true;

  constructor(
    private userAccountService: UserAccountService
  ) {}

  ngOnInit(): void {
    console.debug('Checking if user has access to the button');
    if (this.requiredRoles !== undefined && this.requiredRoles.length > 0) {
      console.debug('Checking if user has access to the button');
      // Default to hidden
      this.buttonVisible = false;
      this.requiredRoles.map((role) => {
        if (this.userAccountService.isAccountInGroup(role))
        {
          this.buttonVisible = true;
          return;
        }
      });
      if (!this.buttonVisible && this.userAccountService.isAccountInGroup(SecurityRole.SuperUser)) {
        this.buttonVisible = true;
      }
    }

    if (!this.buttonVisible) {
      return;
    }

    switch (this.type) {
    case ButtonType.Add: {      
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-orange-500 hover:bg-orange-300 focus:ring-4 focus:ring-orange-300 font-medium text-sm px-3 py-2 mb-2 dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-900';
      this.buttonIconClass = 'mdi mdi-plus';
      this.showIcon = true;
      break;
    }
    case ButtonType.Edit: {
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium text-sm px-3 py-2 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900';
      this.buttonIconClass = 'mdi mdi-file-document-edit';
      this.showIcon = true;
      break;
    }
    case ButtonType.Delete: {
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-sm px-3 py-2 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900';
      this.buttonIconClass = 'mdi mdi-delete-forever-outline';
      this.showIcon = true;
      break;
    }
    case ButtonType.Save: {
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium text-sm px-3 py-2 mr-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900';
      this.buttonIconClass = 'mdi mdi-content-save-outline';
      this.showIcon = true;
      break;
    }    
    case ButtonType.Cancel: {
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium text-sm px-3 py-2 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900';
      this.buttonIconClass = 'mdi mdi-close';
      this.showIcon = true;
      break;
    }    
    case ButtonType.Volunteers: {
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-jw-blue-700 hover:bg-jw-blue-800 focus:ring-4 focus:ring-jw-blue-300 font-medium text-sm px-3 py-2 mr-2 mb-2 dark:bg-jw-blue-600 dark:hover:bg-jw-blue-700 dark:focus:ring-jw-blue-900';
      this.buttonIconClass = 'mdi mdi-account-tie';
      this.showIcon = true;
      break;
    }
    case ButtonType.VolunteerEdit: {
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-jw-blue-700 hover:bg-jw-blue-800 focus:ring-4 focus:ring-jw-blue-300 font-medium text-sm px-3 py-2 mr-2 mb-2 dark:bg-jw-blue-600 dark:hover:bg-jw-blue-700 dark:focus:ring-jw-blue-900';
      this.buttonIconClass = 'mdi mdi-account-edit';
      this.showIcon = true;
      break;
    }
    case ButtonType.Assignments: {
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-jw-blue-700 hover:bg-jw-blue-800 focus:ring-4 focus:ring-jw-blue-300 font-medium text-sm px-3 py-2 mr-2 mb-2 dark:bg-jw-blue-600 dark:hover:bg-jw-blue-700 dark:focus:ring-jw-blue-900';
      this.buttonIconClass = 'mdi mdi-clipboard-account-outline';
      this.showIcon = true;
      break;      
    }
    case ButtonType.Search: {
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-jw-blue-700 hover:bg-jw-blue-800 focus:ring-4 focus:ring-jw-blue-300 font-medium text-sm px-3 py-2 mr-2 mb-2 dark:bg-jw-blue-600 dark:hover:bg-jw-blue-700 dark:focus:ring-jw-blue-900';
      this.buttonIconClass = 'mdi mdi-magnify';
      this.showIcon = true;
      break;      
    }
    case ButtonType.AddGrade: {
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-jw-blue-700 hover:bg-jw-blue-800 focus:ring-4 focus:ring-jw-blue-300 font-medium text-sm px-3 py-2 mr-2 mb-2 dark:bg-jw-blue-600 dark:hover:bg-jw-blue-700 dark:focus:ring-jw-blue-900';
      this.buttonIconClass = 'mdi mdi-format-annotation-plus';
      this.showIcon = true;
      break;
    }
    case ButtonType.Primary:
    default:
      this.buttonClass = 'focus:outline-none rounded-lg text-white bg-jw-blue-700 hover:bg-jw-blue-800 focus:ring-4 focus:ring-jw-blue-300 font-medium text-sm px-3 py-2 mr-2 mb-2 dark:bg-jw-blue-600 dark:hover:bg-jw-blue-700 dark:focus:ring-jw-blue-900';
      this.showIcon = false;
      break;
    }

    if (this.styleClass) {
      this.buttonClass = `${this.buttonClass} ${this.styleClass}`;
    }
  }
}
