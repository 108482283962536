<section class="p-content">
    <header class="p-6 rounded-lg mb-2 bg-white border border-jw-gray-200 shadow-md hover:bg-jw-gray-100
                   dark:bg-jw-gray-800 dark:border-jw-gray-700 dark:hover:bg-jw-gray-700"
        >
        <p-skeleton *ngIf="!isLoaded" class="mb-3 h-2" height="1.5rem" width="100%"></p-skeleton>
        <h1 *ngIf="isLoaded" class="w-full block text-lg font-bold tracking-tight text-jw-gray-900 dark:text-white">
            Details: {{ facility.name }} [{{ facility.city}}],
            Weekend No. {{ weekend.weekendNumber }} ( {{ weekend.weekendDate }} )
            | {{ weekend.weekendType.weekendTypeName }} </h1>
        <p-skeleton *ngIf="!isLoaded" class="w-full block" height="1.5rem" width="100%"></p-skeleton>
        <h4 *ngIf="isLoaded" class="w-full block">Technical Contact: {{ technicalContact.firstName }} {{ technicalContact.lastName }}
        </h4>
    </header>
    <main>
        <div *ngIf="isSuperUser && isLoaded" id="accordion-collapse" data-accordion="collapse" class="mb-6">
            <h2 id="accordion-collapse-heading-1">
                <button type="button" class="flex justify-between items-center px-5 py-2 w-full font-medium text-left text-jw-gray-500 rounded-t-xl rounded-b-xl border
                    border-jw-gray-200 focus:ring-0 dark:border-jw-gray-700 dark:text-jw-gray-400
                    hover:bg-jw-gray-100 dark:hover:bg-jw-gray-800" (click)="toggleAdminFunctions()" id="adminFunctionButton"
                    data-accordion-target="#accordion-collapse-body-1" aria-expanded="true"
                    aria-controls="accordion-collapse-body-1">
                    <h4 class="text-xl font-bold tracking-tight text-jw-gray-900 dark:text-white">Admin Functions:
                    </h4>
                    <svg id="adminFunctionIcon" data-accordion-icon class="w-6 h-6 rotate-0 shrink-0 duration-300"
                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"></path>
                    </svg>
                </button>
            </h2>
            <div id="adminFunctionsBody" class="hidden duration-300" aria-labelledby="accordion-collapse-heading-1">
                <div class="p-5 border border-t-0 border-jw-gray-200 dark:border-jw-gray-700 dark:bg-jw-gray-900">
                    <div class="w-full">
                        <label class="font-normal text-jw-gray-700 dark:text-jw-gray-400">Import Initial Grades for
                            City:</label>
                        <p-checkbox class="initial-grade-checkbox" [binary]="true" name="group1"
                            [(ngModel)]="isInitialGradeImport" inputId="isInitialGrade" (onChange)="onCheckboxChange()">
                        </p-checkbox>
                    </div>
                    <div class="w-full">
                        <label class="font-normal text-jw-gray-700 dark:text-jw-gray-400">Select an Excel File to
                            import:</label>
                        <p-fileUpload #weekendImportFile name="files[]" [showUploadButton]="true"
                            [showCancelButton]="false" [url]="importUrl" (onUpload)="onUpload()" accept=".xlsx"
                            [maxFileSize]="100000000">
                        </p-fileUpload>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full mb-6">
            <h3 class="mb-2 text-xl font-bold tracking-tight text-jw-gray-900 dark:text-white">Assignments:</h3>
            <div class="w-full" *ngIf="assignments">
                <p-table [value]="assignments" [paginator]="true" [rows]="10" styleClass="w-full">
                    <ng-template pTemplate="header">
                        <tr>
                            <th scope="col">Assignment</th>
                            <th scope="col">Volunteer</th>
                            <th scope="col">Current Grade</th>
                            <th scope="col" class="w-2/12"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td>
                                {{ rowData | assignment }}
                            </td>
                            <td>
                                {{ rowData.volunteer.lastName }}, {{ rowData.volunteer.firstName }}
                            </td>
                            <td>
                                {{ rowData.grade | grade:0:rowData }}
                            </td>
                            <td class="text-center">
                                <app-button [hideLabel]="true" label="Add grade for assignment" class="mr-2"
                                    [type]="buttonTypes.Add" (click)="openAddGradeModal(rowData)"></app-button>
                                <app-button [hideLabel]="true" label="Remove Volunteer From Weekend"
                                    [type]="buttonTypes.Delete" (click)="removeRoleAssignment(rowData)">
                                </app-button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-button [type]="buttonTypes.Add" label="Add Assignment" [routerLink]="['./add-assignment']"
                            [requiredRoles]="[securityRole.SuperUser, securityRole.TechnicalContact]">
                        </app-button>
                    </ng-template>
                </p-table>
            </div>
        </div>

        <div class="w-full">
            <h3>Congregations for weekend:</h3>
            <div class="w-full">
                <p-table [value]="congregations" [paginator]="true" [rows]="10" styleClass="w-full" [loading]="isLoadingCongregationList">
                    <ng-template pTemplate="header">
                        <tr>
                            <th scope="col">Number<p-columnFilter type="text" field="congregationNumber">
                                </p-columnFilter>
                            </th>
                            <th scope="col">Name<p-columnFilter type="text" field="congregationName">
                                </p-columnFilter>
                            </th>
                            <th scope="col">Circuit</th>
                            <th scope="col" class="w-2/12"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td>
                                {{ rowData.congregationNumber }}
                            </td>
                            <td>
                                {{ rowData.congregationName }}
                            </td>
                            <td>
                                {{ rowData.circuit | circuit: rowData.circuitSection }}
                            </td>
                            <td class="text-center">
                                <app-button [hideLabel]="true" label="Remove congregation from weekend"
                                    [type]="buttonTypes.Delete" (click)="removeCongregation(rowData)">
                                </app-button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="summary">
                        <app-button [type]="buttonTypes.Add" label="Add Congregation to weekend"
                            (click)="showAddCongregationToWeekend()"></app-button>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </main>
</section>

<p-dialog header="Add Congregation to Weekend" [(visible)]="showAddCongregationDialog" [focusOnShow]="true"
    showEffect="fade" [modal]="true" [style]="{ width: '50vw' }"
    styleClass="bg-jw-gray-100 text-jw-gray-900 dark:bg-jw-gray-800 dark:text-white">
    <div class="w-full mb-4">
        <label for="selectedCongregation"
            class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Search for congregation:</label>

        <p-autoComplete [(ngModel)]="selectedCongregation" [suggestions]="selectCongregationList"
            (completeMethod)="congregationSearch($event)" appendTo="body" field="congregationName"
            styleClass="w-full"
            inputStyleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-1.5
                        dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
            <ng-template let-suggestion pTemplate="item">
                <div class="p-autocomplete-item-content">
                    <span>{{suggestion.congregationNumber}} - {{suggestion.congregationName}}</span>
                </div>
            </ng-template>
        </p-autoComplete>
    </div>
    <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <app-button [type]="buttonTypes.Save" label="Save" (click)="save()"></app-button>
            <app-button [type]="buttonTypes.Cancel" label="Cancel" (click)="cancel()"></app-button>
        </div>
    </p-footer>
</p-dialog>

<app-volunteer-rating-modal #volunteerRatingModal (onGradeSaved)="handleGradeSaved()"></app-volunteer-rating-modal>
