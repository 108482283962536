<div class="block p-6 rounded-lg mb-4 bg-jw-gray-50 border border-jw-gray-50 dark:border-jw-gray-800 shadow-md dark:bg-jw-gray-800">
    <h4 class="mb-2 text-2xl font-bold tracking-tight text-jw-gray-900 dark:text-white">Congregation List:</h4>
    <p class="font-normal text-jw-gray-700 dark:text-jw-gray-400">
        Add/Edit congregations. For the congregation number, please use the Branch assigned congregation number.
    </p>
    <p class="font-normal text-jw-gray-700 dark:text-jw-gray-400" *ngIf="!(congregationList)"><em>Loading...</em></p>
</div>
<div class="w-full">
    <p-table
        [value]="congregationList"
        [paginator]="true"
        (rows)="pageSize"
        (loading)="isSearching"
        [showCurrentPageReport]="true"
        [lazy]="true"
        (onLazyLoad)="lazyLoadCongregations($event)"
        [rowsPerPageOptions]="[10, 20, 40, 60]"
        [rows]="pageSize"
        [totalRecords]="totalRecords"
        dataKey="congregationNumber"
        responsiveLayout="stack"
        editMode="row"
        syleClass="border rounded-lg shadow overflow-hidden dark:border-jw-gray-700 dark:shadow-jw-gray-900"
        tableStyleClass="min-w-full divide-y divide-jw-gray-200 dark:divide-jw-gray-700"
    >
        <ng-template pTemplate="header">
            <tr>
                <th  scope="col" class="rounded-tl-lg">
                    Number
                    <p-columnFilter type="numeric" field="congregationNumber"
                        [showMenu]="false" [showClearButton]="true" [useGrouping]="false"
                        [maxFractionDigits]="0" [minFractionDigits]="0"
                    ></p-columnFilter>
                </th>
                <th scope="col">
                    Name
                    <p-columnFilter type="text" field="congregationName"
                        [showMenu]="false" [showClearButton]="true"
                    ></p-columnFilter>
                </th>
                <th scope="col">
                    Circuit
                </th>
                <th scope="col" class="rounded-tr-lg"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="rowData">
                <td width="20%">
                    <span class="p-column-title">Number:</span>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-300 text-sm rounded-lg block w-full p-2.5 
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-gray-400 "
                            pInputText type="number" readonly disabled aria-disabled="true"
                                [(ngModel)]="rowData.congregationNumber" required numericOnlyValidator />
                        </ng-template>
                        <ng-template pTemplate="output">{{ rowData.congregationNumber }}</ng-template>
                    </p-cellEditor>
                </td>
                <td width="40%">
                    <span class="p-column-title">Name:</span>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <input class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            pInputText type="text" [(ngModel)]="rowData.congregationName" required />
                        </ng-template>
                        <ng-template pTemplate="output">{{ rowData.congregationName }}</ng-template>
                    </p-cellEditor>
                </td>
                <td width="30%">
                    <span class="p-column-title">Circuit:</span>
                    <p-cellEditor>
                        <ng-template pTemplate="input">
                            <p-dropdown styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            [options]="circuits" [(ngModel)]="rowData.circuit" [filter]="true"></p-dropdown>
                            <br />Section: <input class="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            pInputText type="text" [(ngModel)]="rowData.circuitSection" required />
                        </ng-template>
                        <ng-template pTemplate="output">{{ rowData.circuit | circuit: rowData.circuitSection }}</ng-template>
                    </p-cellEditor>
                </td>
                <td width="10%">
                    <app-button *ngIf="!editing" pInitEditableRow [initRowEdit]="true" [hideLabel]="true"
                        label="Edit Congregation" [type]="buttonTypes.Edit" (click)="onRowEditInit(rowData)"></app-button>

                    <app-button *ngIf="editing" pSaveEditableRow [hideLabel]="true"
                        label="Save Circuit" [type]="buttonTypes.Save" (click)="onRowEditSave(rowData)"></app-button>
                    <app-button *ngIf="editing" pCancelEditableRow [hideLabel]="true"
                        label="Save Circuit" [type]="buttonTypes.Cancel" (click)="onRowEditCancel(rowData, ri)"></app-button>


                    <app-button *ngIf="!editing" [type]="buttonTypes.Volunteers"
                        [routerLink]="['/congregation', rowData.congregationNumber, 'volunteers']"
                        [hideLabel]="true"
                        rel="tooltip" label="Congregation Volunteers"></app-button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <app-button [type]="buttonTypes.Add" label="Add Congregation" [routerLink]="['/congregation/add']" [requiredRoles]="[securityRole.SuperUser, securityRole.TechnicalContact]"></app-button>
        </ng-template>
    </p-table>
</div>
