import { Component, OnInit } from '@angular/core';
import { CongregationService } from '../../../modules/congregations/services/congregation.service';
import { Congregation } from '../../../models/congregation';
import { MessageService, SelectItem, LazyLoadEvent, FilterMetadata } from 'primeng/api';
import { ClonerService } from 'src/app/core/services/cloner.service';
import { CircuitPipe } from 'src/app/modules/shared/pipe/circuit.pipe';
import { ButtonType } from '../../components/button/buttonType';
import { SecurityRole } from '../../security/securityRole';
import { CircuitService } from 'src/app/modules/congregations/services/circuit.service';
import { TableLazyLoadEvent } from 'primeng/table';

@Component({
  selector: 'app-congregation-list',
  templateUrl: './congregation-list.component.html'
  })
export class CongregationListComponent implements OnInit {
  circuits: SelectItem[] = [];
  congregation: Congregation;
  selectedCircuit: SelectItem;
  securityRole = SecurityRole;

  buttonTypes = ButtonType;
  page = 1;
  pageSize = 10;
  congregationName: string;
  congregationNumber: number;
  circuit: string;
  totalRecords: number;
  isSearching = false;

  congregationList: Congregation[];
  clonedCongregationList: { [s: string]: Congregation; } = {};

  showAddCongregationDialog = false;

  constructor(
    private congregationService: CongregationService,
    private circuitService: CircuitService,
    private messageService: MessageService,
    private clonerService: ClonerService
  ) { }

  ngOnInit(): void {
    this.page = 1;
    this.pageSize = 10;
    // this.loadCircuts();
  }

  onRowEditInit(congregation: Congregation): void {
    this.clonedCongregationList[congregation.congregationNumber] = this.clonerService.deepClone<Congregation>(congregation);
  }

  onRowEditSave(congregation: Congregation): void {
    if (congregation.congregationNumber > 0) {
      delete this.clonedCongregationList[congregation.congregationNumber];
      this.congregationService.updateCongregation(congregation)
        .then(() => {
          this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Congregation updated' });
          this.loadCongregations();
        });
    } else {
      this.congregationService.createCongregation(congregation)
        .then(() => {
          this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Congregation created' });
          this.loadCongregations();
        });
    }
  }

  onRowEditCancel(congregation: Congregation, index: number): void {
    this.congregationList[index] = this.clonedCongregationList[congregation.congregationNumber];
    delete this.clonedCongregationList[congregation.congregationNumber];
  }

  lazyLoadCongregations(event: TableLazyLoadEvent): void {
    console.debug(event);
    this.isSearching = true;
    this.page = event.first;
    this.pageSize = event.rows === undefined ? 10 : event.rows;
    this.congregationNumber = event.filters.congregationNumber ? (event.filters.congregationNumber as FilterMetadata).value : 0;
    this.congregationName = event.filters.congregationName ? (event.filters.congregationName as FilterMetadata).value : '';
    this.loadCongregations();
  }

  loadCongregations(): void {
    console.debug('loadCongregations');
    console.debug({
      'page': this.page,
      'pageSize': this.pageSize,
      'congregationNUmber': this.congregationNumber
    });
    this.congregationService.getCongregations(this.page, this.pageSize, this.congregationNumber, this.congregationName, '')
      .then(congregations => {
        this.congregationList = congregations.records;
        this.totalRecords = congregations.totalRecords;
        this.isSearching = false;
      });
  }

  loadCircuts(): void {
    const circuitPipe = new CircuitPipe();
    this.circuitService.getCircuits()
      .then(data => {
        data.forEach((el) => this.circuits.push({ label: circuitPipe.transform(el), value: el }));
      });
  }

  showDialogToAdd(): void {
    this.congregation = new Congregation();
    this.showAddCongregationDialog = true;
  }

  onInputBlur(): void {
    const form = document.getElementById('editForm');
    if (form) {
      if (!form.classList.contains('was-validated')) {
        form.classList.add('was-validated');
      }
    }
  }

  save(): void {
    this.congregation.congregationNumber = parseInt(this.congregation.congregationNumber.toString());
    this.congregationService.createCongregation(this.congregation)
      .then(() => {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Congregation created' });
        this.loadCongregations();
        this.showAddCongregationDialog = false;
      })
      .catch((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error
        });
      });
  }

  cancel(): void {
    this.showAddCongregationDialog = false;
  }
}
