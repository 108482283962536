import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService, SelectItem } from 'primeng/api';
import { ButtonType } from 'src/app/features/components/button/buttonType';
import { Congregation } from 'src/app/models/congregation';
import { CircuitService } from 'src/app/modules/congregations/services/circuit.service';
import { CongregationService } from 'src/app/modules/congregations/services/congregation.service';
import { CircuitPipe } from 'src/app/modules/shared/pipe/circuit.pipe';

@Component({
  selector: 'app-congregation-add',
  templateUrl: './congregation-add.component.html',
  styleUrls: ['./congregation-add.component.scss']
  })
export class CongregationAddComponent implements OnInit {

  submitted = false;
  form: FormGroup;

  circuits: SelectItem[] = [];
  selectedCircuit: SelectItem;
  congregation: Congregation;

  buttonTypes = ButtonType;

  constructor(

    private congregationService: CongregationService,
    private circuitService: CircuitService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.congregation = new Congregation();
    this.loadCircuits();

    this.form = new FormGroup({
      congregationNumber: new FormControl(this.congregation.congregationNumber, [ Validators.required, Validators.minLength(5) ]),
      congregationName: new FormControl(this.congregation.congregationName, [ Validators.required ]),
      congregationCircuit: new FormControl(this.selectedCircuit, [ Validators.required ]),
      congregationCircuitSection: new FormControl(this.congregation.circuitSection)
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get f(): any { return this.form.controls; }

  loadCircuits(): void {
    const circuitPipe = new CircuitPipe();
    this.circuitService.getCircuits()
      .then(data => {
        data.forEach((el) => this.circuits.push({ label: circuitPipe.transform(el), value: el }));
      });
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.congregation.congregationNumber = parseInt(this.f.congregationNumber.value.toString());
    this.congregation.circuit = this.f.congregationCircuit.value;
    this.congregation.congregationName = this.f.congregationName.value;
    this.congregation.circuitSection = this.f.congregationCircuitSection.value;
    this.congregationService.createCongregation(this.congregation)
      .then(() => {
        this.messageService.add({ severity: 'success', summary: 'Saved', detail: 'Congregation created' });
        this.router.navigate(['/congregation']);
      })
      .catch((error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.error
        });
      });
  }
}
