<p-dialog header="Add Assignment Role" [(visible)]="showAddRoleAssignmentDialog" [focusOnShow]="true" showEffect="fade"
    [style]="{ width: '50vw' }" styleClass="bg-jw-gray-100 text-jw-gray-900 dark:bg-jw-gray-800 dark:text-white"
    [modal]="true">
    <div>
        <div class="mb-6">
            <label for="selectedVolunteer" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Brother:</label>
            <p-autoComplete [(ngModel)]="selectedVolunteer" [suggestions]="volunteerSearchList"
                (completeMethod)="searchVolunteers($event)" field="displayName" 
                class="block w-full" styleClass="w-full"
                inputStyleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [disabled]="true" aria-disabled="true" id="selectedVolunteer" name="selectedVolunteer">
                <ng-template let-volunteer pTemplate="item">
                    {{ volunteer.lastName }}, {{ volunteer.firstName }}
                </ng-template>
            </p-autoComplete>
        </div>
        <div class="mb-6">
            <label for="selectedJob" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Assignment:</label>
            <p-dropdown id="selectedJob" name="selectedJob" appendTo="body" [options]="conventionJobs" 
                inputStyleClass="p-2.5"
                styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [(ngModel)]="selectedJob" (onChange)="populateSubRoles()" [required]="true" aria-required [autofocus]="true" focus>
            </p-dropdown>
        </div>
        <div class="mb-6">
            <label for="selectedJobRole" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Role:</label>
            <p-dropdown id="selectedJobRole" name="selectedJobRole" appendTo="body" [options]="conventionJobRoles"
                inputStyleClass="p-2.5"
                styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [(ngModel)]="selectedJobRole" [required]="true" aria-required [autofocus]="true" focus>
            </p-dropdown>
        </div>
        <div class="mb-6">
            <label for="selectedJobSubRole" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Sub role:</label>
            <p-dropdown id="selectedJobSubRole" name="selectedJobSubRole" appendTo="body" [options]="filteredJobSubRoles"
                inputStyleClass="p-2.5"
                styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full
                            dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                [(ngModel)]="selectedJobSubRole" [required]="true" aria-required [autofocus]="true" focus>
            </p-dropdown>
        </div>
    </div>
    <p-footer>
        <app-button [type]="buttonTypes.Save" [label]="saveButtonText" (click)="save()"></app-button>
        <app-button [type]="buttonTypes.Cancel" label="Cancel" (click)="cancel()"></app-button>
    </p-footer>
</p-dialog>