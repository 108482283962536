import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from '../../features/search/search/search.component';
import { PageSearchComponent } from './pages/page-search/page-search.component';

// 3rd Party Components
import { TableModule } from 'primeng/table';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { SharedModule } from '../shared/shared.module';
import { SkeletonModule } from 'primeng/skeleton';
import { AccordionModule } from 'primeng/accordion';
import { BlockUIModule } from 'primeng/blockui';
import { PanelModule } from 'primeng/panel';

@NgModule({
  declarations: [
  SearchComponent,
  PageSearchComponent,
  ],
  imports: [
  CommonModule,
  FormsModule,
  TableModule,
  DialogModule,
  DynamicDialogModule,
  DataViewModule,
  DropdownModule,
  CheckboxModule,
  EditorModule,
  PanelModule,
  AccordionModule,
  BlockUIModule,
  SkeletonModule,
  AutoCompleteModule,
  BrowserAnimationsModule,
  ReactiveFormsModule,
  SearchRoutingModule,
  SharedModule
  ],
  providers: []
  })
export class SearchModule { }
