/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-add-congregations-component',
  templateUrl: './page-add-congregations-component.component.html',
  styleUrls: ['./page-add-congregations-component.component.scss']
})
export class PageAddCongregationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
