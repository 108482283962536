<section class="p-content" *ngIf="isLoaded">
    <div class="p-6 rounded-lg mb-4 bg-white border border-jw-gray-200 shadow-md 
                hover:bg-jw-gray-100 dark:bg-jw-gray-800 dark:border-jw-gray-700 dark:hover:bg-jw-gray-700">
        <h1 class="mb-2 text-2xl font-bold tracking-tight text-jw-gray-900 dark:text-white">Details: {{ facility.name }}
            [{{ facility.city}}], Weekend No. {{ weekend.weekendNumber }} </h1>
        <h4 class="mb-2 text-xl font-bold tracking-tight text-jw-gray-900 dark:text-white">Technical Contact: {{
            technicalContact.firstName }} {{ technicalContact.lastName }}</h4>
        <h4 class="mb-2 text-normal font-bold tracking-tight text-jw-gray-900 dark:text-white">Add weekend assignment:
        </h4>
    </div>
    <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="w-full">
                <div class="mb-4">
                    <label for="selectedJob"
                        class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Brother:</label>
                    <p-autoComplete [suggestions]="volunteerSearchList" (completeMethod)="searchVolunteers($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.selectedVolunteer.errors }" name="selectedVolunteer"
                        id="selectedVolunteer"
                        styleClass="w-full block"
                        inputStyleClass="autocomplete bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 
                                dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        field="displayName" [dropdown]="true" formControlName="selectedVolunteer">
                        <ng-template let-volunteer pTemplate="item">
                            {{ volunteer.lastName }}, {{ volunteer.firstName }} [{{volunteer.congregation.congregationName}}]
                        </ng-template>
                    </p-autoComplete>
                    <div *ngIf="submitted && f.selectedVolunteer.errors" class="mt-2 text-red-700 dark:text-red-500">
                        Please select a volunteer.
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <label for="selectedJob" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Assignment:</label>
                <p-dropdown id="selectedJob" appendTo="body" [options]="conventionJobs"
                    [ngClass]="{ 'is-invalid': submitted && f.selectedJob.errors }" name="selectedJob"
                    formControlName="selectedJob" (onChange)="populateSubRoles()" [required]="true" aria-required [autofocus]="true" focus 
                    styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-0.5 
                                dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </p-dropdown>
                <div *ngIf="submitted && f.selectedJob.errors" class="mt-2 text-red-700 dark:text-red-500">
                    The Assignment is required.
                </div>
            </div>
            <div class="mb-4">
                <label for="selectedJob" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Role:</label>
                <p-dropdown id="selectedJobRole" appendTo="body" [options]="conventionJobRoles" [required]="true" 
                    [ngClass]="{ 'is-invalid': submitted && f.selectedJob.errors }"
                    formControlName="selectedJobRole" aria-required [autofocus]="true" focus
                    styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-0.5 
                                dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </p-dropdown>
                <div *ngIf="submitted && f.selectedJobRole.errors" class="mt-2 text-red-700 dark:text-red-500">
                    The Role is required.
                </div>
            </div>
            <div class="mb-4">
                <label for="selectedJob" class="block mb-2 text-sm font-medium text-jw-gray-900 dark:text-jw-gray-300">Sub role:</label>
                <p-dropdown id="selectedJobSubRole" appendTo="body" [options]="filteredJobSubRoles" [autofocus]="true" focus
                    styleClass="bg-jw-gray-50 border border-jw-gray-300 text-jw-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-0.5 
                                dark:bg-jw-gray-700 dark:border-jw-gray-600 dark:placeholder-jw-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                </p-dropdown>
            </div>
            <div class="mb-4">
                <div class="text-right">
                    <app-button [type]="buttonTypes.Save" label="Save"></app-button>
                    <app-button [type]="buttonTypes.Cancel" label="Cancel" [routerLink]="['..']"></app-button>
                </div>
            </div>
        </form>
    </div>
</section>