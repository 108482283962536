import { Pipe, PipeTransform } from '@angular/core';
import { Circuit } from '../../../models/circuit';

@Pipe({
  name: 'circuit'
})
export class CircuitPipe implements PipeTransform {

  transform(circuitValue: Circuit, sectionCode?: string): string {
    try {
      if (circuitValue){
        if (sectionCode) {
          if (circuitValue.language.toUpperCase() === 'E') {
            return `${circuitValue.stateCode}-${circuitValue.number}-${sectionCode}`;    
          }
          return `${circuitValue.stateCode}-${circuitValue.number}-${sectionCode}-${circuitValue.language.toUpperCase()}`;
        }
        if (circuitValue.language.toUpperCase() === 'E') {
          return `${circuitValue.stateCode}-${circuitValue.number}`;    
        }
        return `${circuitValue.stateCode}-${circuitValue.number}-${circuitValue.language.toUpperCase()}`;
      }
      else {
        return 'No Circuit';
      }
    } catch (error) {
      return 'ERROR - ' + error.message;
    }
  }
}