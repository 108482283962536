import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalCustomNavigationClient,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { UserAccountService } from './core/services/user-account.service';
import { HeaderTitleService } from './core/services/header-title.service';
import * as environment from 'src/environments/environment';
import posthog from 'posthog-js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'Convention Personel Tool';
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  public Version: string = environment.environment.version;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private location: Location,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private userAccountService: UserAccountService,
    private headerTitleService: HeaderTitleService,
  ) {
    // Custom navigation set for client-side navigation.
    // See performance doc for details: https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular/docs/v2-docs/performance.md
    const customNavigationClient = new MsalCustomNavigationClient(
      authService,
      this.router,
      this.location,
    );
    this.authService.instance.setNavigationClient(customNavigationClient);
  }

  ngOnInit(): void {
    const appTitle = this.titleService.getTitle();
    console.debug('Initializing Posthog');
    posthog.init('phc_pt9qLYl3nJueqsCBOr48cjEHcJ0N6ZpwCO2Zkw0T102', {
      api_host: 'https://app.posthog.com',
      loaded: function () {
        console.debug('Posthog loaded');
      },
    });
    console.debug('Posthog initialized, loading feature flags');
    posthog.onFeatureFlags(function () {
      console.debug('Posthog feature flags loaded');
    });

    this.isIframe = window !== window.parent && !window.opener;
    this.checkAccount();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.checkAccount();
        if (this.userAccountService.isLoggedIn) {
          const user = this.userAccountService.getAccount();
          if (user) {
            posthog.identify(user.username, {
              email: user.username,
              environment: environment.environment.environment,
            });
          }
        }
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return appTitle;
        }),
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl + ' | ' + this.title);
        this.headerTitleService.setTitle(ttl);
        posthog.capture('$pageview', {
          page: ttl,
          environment: environment.environment.environment,
        });
      });
  }

  checkAccount(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    this.userAccountService.checkAccount();
  }

  loginRedirect(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup(): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService
        .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService
        .loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          posthog.identify(response.account.username, {
            email: response.account.username,
            environment: environment.environment.environment,
          });
        });
    }
  }

  logout(popup?: boolean): void {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
